.server-error {
  height: 100vh;
  display: flex
;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .server-error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}
