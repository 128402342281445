@import '../../assets/mixins.less';

.classifications-page {
  .ui.header {
    width: 100%;
    .classification-header {
      display: flex;
      justify-content: space-between;
      flex: 1;
      align-items: flex-start;
      flex-direction: column;

      .header-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .header-actions {
          display: flex;
          align-self: flex-end;
          gap: 0.5rem;
        }
      }
    }
  }
}

.classifications-container {
  width: 100%;

  .list {
    display: flex;
    flex-flow: column nowrap;

    .row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-bottom: 1rem;

      .bar-wrapper {
        flex-grow: 1;
      }
    }

    .title {
      width: 12rem;
      margin-right: 1.8rem;
      font-weight: 500;
    }

    .bar {
      height: 2rem;
      background-color: var(--color-primary);
      min-width: 1rem;
      border-radius: 4px;

      &.unclassified {
        background-color: var(--color-tertiary);
      }
    }
  }
}
.class-group-by {
  display: flex;
  align-items: center;
  label {
    color: var(--color-text-secondary);
    text-transform: uppercase;
  }

  .ui.selection.dropdown {
    display: flex;
  }
  .ui.dropdown {
    width: 10rem;
    height: 2.3rem;
    min-width: unset;
    min-height: unset;
    display: inline-flex;
    .item {
      display: flex;
      align-items: center;
    }
    .text {
      line-height: 1.25;
    }
    .dropdown.icon {
      padding-top: 0.72rem;
      padding-bottom: 8px;
    }
  }
  label,
  .ui.dropdown .text {
    .font-medium(0.86rem,1.5em);
  }
}
.ui.button.btn-create-template {
  color: var(--color-white);
  outline: none;
  border: none;
  padding: 1rem 1.2rem;
  background: var(--color-blue-2);
  border-radius: 1.5rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.ui.grid.pagination-controls {
  padding-top: 1.5rem;
}

.classified .title {
  .text-primary;
}
.unclassified .title {
  .text-tertiary;
}

.list-files-page {
  .ui.header {
    .font-bold(1.43rem, 1.4em);
    color: #586069;
    margin: 0;
  }
}

.datasource-objects {
  .table_controls {
    justify-content: end;
  }
  .cards-list-header {
    .font-semiBold(1rem, 1.43em);
    color: var(--color-river-bed);
  }
}

@media screen and (max-width: 992px) {
  .classifications.chart {
    min-width: 100%;
  }
}

/** Class card **/
.cards-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 1.1rem;

  .categories-card {
    position: relative;
    border: 1px solid var(--color-mystic);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 1.8rem;
    background-color: var(--color-white);

    &:hover {
      filter: drop-shadow(0px 4px 4px #d7dde2);
    }

    .header {
      display: flex;
      flex-flow: row nowrap;
      &:not(.mip-header) {
        align-items: center;
      }
      .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: var(--color-small-background);
        margin-right: 1.8rem;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .total {
        display: flex;
        flex-flow: column nowrap;
        flex-grow: 1;

        .count {
          font-weight: 600;
          font-size: 1.45rem;
          line-height: 1.45rem;
          margin-bottom: 0.4rem;
          color: var(--color-class-card-count);
        }

        .title {
          line-height: 0.92rem;
        }
      }

      .risk {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.3rem;
        width: 2.3rem;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        background-color: var(--color-warning-background);
        color: var(--color-white);
        font-weight: bold;
        font-size: 0.85rem;
        line-height: 0.85rem;

        &.no {
          background-color: var(--color-white);
          border: 2px solid var(--color-status-ok);
          height: 1.8rem;
          width: 1.8rem;
        }
      }
    }
    .separator {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-top: 1.8rem;
      margin-bottom: 0.5rem;
      color: var(--color-class-card-description);

      .uppercase {
        text-transform: uppercase;
      }
      .obj-count {
        .font-semiBold(1.15rem, 1.5em);
        margin-bottom: 1px;
        color: var(--color-black);
      }
      .obj-text {
        .font-semiBold(1rem, 1.43em);
        color: #959da5;
      }
    }

    .sub-classes {
      display: flex;
      flex-flow: row wrap;

      .item {
        margin: 0.5rem 0.8rem 0 0;

        &:hover {
          opacity: 0.7;
        }
      }
    }
    .classification-card-actions {
      position: absolute;
      top: 0.5rem;
      right: -0.35rem;
    }
  }

  .mip-label {
    .font-semiBold(1rem, 1.43em);
    color: var(--color-emoby-clay);
    .name-label {
      display: flex;
      align-items: center;
    }
    .name-set {
      font-size: 0.86rem;
      color: #959da5;
    }
    .separator {
      flex-flow: column nowrap;
      align-items: flex-start;
      border: 0;
    }
  }
}

@media screen and (min-width: 650px) {
  .cards-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1200px) {
  .cards-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1550px) {
  .cards-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1850px) {
  .cards-list {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 2100px) {
  .cards-list {
    grid-template-columns: repeat(6, 1fr);
  }
}

/** Top 3 classifications widget **/
.classifications-or-labels.widget-grouped {
  flex-grow: 1;
  display: flex;
  min-width: 13rem;
  position: relative;
  height: 100%;

  &.vertical {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .legend {
      display: flex;
      flex-flow: row wrap;
      align-items: baseline;

      .item {
        margin-right: 0.5rem;
      }
    }
  }
  &.horizontal {
    flex-flow: row nowrap;
    gap: var(--size-24px);
    justify-content: center;
    align-items: center;

    .legend {
      display: flex;
      flex-flow: column nowrap;

      .item {
        line-height: 1.2em;
      }

      .key {
        width: 4rem;
        margin-right: 1rem;
      }
    }
  }

  .empty-state-text {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .chart-text {
    text-align: center;
    margin-top: -1rem;
    font-size: 0.86rem;
  }

  .recharts-wrapper svg {
    overflow: visible;
  }

  .icon-box {
    display: flex;
  }

  .legend {
    .item {
      .font-normal(0.86rem, 1.5em);
      display: flex;
      align-items: center;
      color: var(--color-river-bed);

      .circle {
        flex-shrink: 0;
        flex-grow: 0;
        width: 0.61rem;
        height: 0.61rem;
        margin-right: 0.3rem;
        border-radius: 50%;
      }
    }
  }
}

// Used for shares overview - do not remove
.classifications.widget-grouped {
  flex-grow: 1;
  display: flex;
  min-width: 13rem;
  position: relative;
  height: 100%;

  &.vertical {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .legend {
      display: flex;
      flex-flow: row wrap;
      align-items: baseline;

      .item {
        margin-right: 0.5rem;
      }
    }
  }
  &.horizontal {
    flex-flow: row nowrap;
    gap: var(--size-24px);
    justify-content: center;
    align-items: center;

    .legend {
      display: flex;
      flex-flow: column nowrap;

      .item {
        line-height: 1.2em;
      }

      .key {
        width: 4rem;
        margin-right: 1rem;
      }
    }
  }

  .empty-state-text {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .chart-text {
    text-align: center;
    margin-top: -1rem;
    font-size: 0.86rem;
  }

  .recharts-wrapper svg {
    overflow: visible;
  }

  .icon-box {
    display: flex;
  }

  .legend {
    .item {
      .font-normal(0.86rem, 1.5em);
      display: flex;
      align-items: center;
      color: var(--color-river-bed);

      .circle {
        flex-shrink: 0;
        flex-grow: 0;
        width: 0.61rem;
        height: 0.61rem;
        margin-right: 0.3rem;
        border-radius: 50%;
      }
    }
  }
}

.classifications-print {
  .row {
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--color-mystic);

    &.table-header {
      color: var(--color-white);
    }

    &.row-header {
      padding-top: 2rem;
      font-weight: 600;
    }

    .header-container {
      width: fit-content;
      display: flex;
      align-items: center;
      & > * {
        display: inline-block;
      }
    }

    &.subclass:nth-child(odd) {
      background-color: #f6f8fa;
    }

    .col {
      .icon-box {
        margin-right: 0.5rem;
      }
      &:first-of-type {
        width: 50%;
      }
    }
  }
  .table-header {
    background-color: #3f4db0;
  }
}

/** Analytics page **/
.analytics-wrapper {
  .analytics-header {
    font-size: 0.85rem;
    font-weight: 500;
    color: var(--color-text-secondary);
    margin-bottom: 1rem;
  }
  p {
    line-height: 65%;
  }
}

.ui.dropdown.group-by-dropdown {
  .row {
    display: flex !important;
    flex-flow: row nowrap;
    align-items: center;
  }
  .text {
    align-self: center;
  }
}

.classification-create-form {
  max-width: 840px;

  h5 {
    font-weight: 600;
  }
  .classification-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .form-label {
      margin-bottom: 0.5rem;
      &.required:after {
        color: var(--color-form-field-error);
        content: ' *';
      }
    }
    .classification-status {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      span {
        min-width: 60px;
      }
    }
    .ui.fitted.toggle.checkbox {
      height: 20px;
      width: 40px;

      &:not(.simple) label:before {
        background: #eceef1 !important;
        border: 1px solid #d1d5da !important;
      }
      &:not(.simple) input:checked ~ label:before {
        background: #36a14c !important;
      }
      &:not(.simple) label:after {
        height: 16px;
        width: 16px;
        translate: none;
        background: #fff;
      }
      &:not(.simple) input:checked ~ label:after {
        left: 22px !important;
      }
    }
    textarea {
      width: 340px;
      padding: 0.67857143em 1em;
      background: #fff;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 4px;
      resize: none;
      &:focus {
        border-color: #85b7d9;
        outline: none;
      }
      &:focus-visible {
        outline: none;
      }
    }
  }
  .add-sub-class-button {
    color: var(--color-primary);
    display: flex;
    align-items: center;
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
    width: fit-content;
    &:hover {
      color: var(--color-primary-background-hover);
    }
  }
  .subclassification-item {
    border-radius: 4px;
    border: 1px solid var(--Border-dark, #d1d5da);
    padding: 2.25rem 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      background: var(--Gray-025, #fafbfc);
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    .delete-icon {
      cursor: pointer;
      color: #6a737d;
      &:hover {
        color: var(--color-error);
      }
    }
    .error {
      display: flex;
      flex-direction: column;
      .input {
        input {
          border-color: var(--color-error);
        }
      }
      .pointing {
        width: fit-content;
      }
    }
  }
  .classification-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    .btn {
      &.btn-confirm {
        background-color: var(--color-primary);
        color: var(--color-white);
        padding: 0.625rem 1.5rem;
        border: none;
      }
      font-size: 1rem;
      font-weight: 500;
      border-radius: 4px;
      padding: 0.625rem 1.5rem;
      border: 1px solid var(--Border-dark, #d1d5da);
    }
  }
}

.classification-settings {
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
  flex: 1;
  border: 1px solid rgba(0, 21, 41, 0.16);
  border-radius: 0.5rem;
  background: #fff;
  .settings-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1rem;
    gap: 0.5rem;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #2899e0;
      border-radius: 0.5rem;
      padding: 0.5rem;
    }
  }
  .settings-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
    .settings-desc {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .heading {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      .settings-description {
        display: inline-block;
        width: 70%;
      }
    }
  }
  .separator {
    border-bottom: 1px solid rgba(0, 21, 41, 0.16);
  }
}

.classification-empty-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 100%;
  width: 100%;
  margin-top: 6rem;
  .enable-classification-button {
    padding: 1rem 1rem;
  }
}
